.adminblock {
	display: block;
	align: left;
	text-align: left;
	background-color: #ffffff;
	color: #000000;
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	margin-right: 0px;
	padding: 20px 200px;
}

.adminheader {
	display: block;
	clear: both;
	padding: 5px 20px;
}

.adminbutton {
	display: block;
	clear: both;
	padding: 5px 20px;
}

.adminbuttonleft{
	display:inline;
	float:left;
}

.adminbuttonright{
	display:inline;
	float:right;
	margin-left:15px;
}

.admintable {
	display: block;
	clear: both;
	padding: 5px 20px;
}

.adminheaderbutton {
	float: right;
}
