.page {
	background-color: #ffffff;
	color: #000000;
   padding-bottom: 30px;
}

.page h2 {
	font-size: 18px;
	margin-top: 10px;
}

.page h3 {
	font-size: 14px;
	display: inline;
}
