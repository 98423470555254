.report {
	display: flex;
	background-color: #ffffff;
	color: #000000;
	margin-top: 50px;
	margin-bottom: 0px;
	margin-left: 20px;
	margin-right: 20px;
	padding: 0px;
}

.report-download {
	display: flex;
	font-size: smaller;
	background-color: #ffffff;
	color: #000000;
	margin-top: 5px;
	margin-bottom: 0px;
	padding: 0px;
   text-align: left;
   margin-left: 180px;
}
.graph-explanation {
    text-align: left;
    margin-left: 180px;
    width: 75%;
}


.tablewrapper {
	margin: 100px;
}

.tablewrapperh {
	margin: 10px;
}

.tablewrapperh h3 {
	font-size: 20px;
}

.custom-tooltip {
	background-color: #ffffff;
	padding: 10px;
	font-size:14px;
	border: 1px solid;
}

.graph-title {
	margin-top: 40px;
	font-size:20px;
}

@media print{
	.report-download {
		display:none !important;
	}
	.report {
		display: block !important;
	}
	.recharts-responsive-container .recharts-wrapper .recharts-surface {
		display: block !important;
		overflow: visible !important;
		overflow-x: visible !important; 
		overflow-y: visible !important;
		width: calc(100%)!important;
		height: auto !important;
	}
}
