.App {
	background-color: #ffffff;
	min-height: 100vh;
	max-height: 100vh;
	display: flex;
	flex-direction: column;
	/*	font-size: calc(8px + 1vmin); */
	font-size: 12px;
	color: white;
}

.sidebar {
	background-color: #bbbbbb;
	min-height: 100vh;
	max-height: auto;
	width: 250px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	top: 0;
        left: 0;
        position: fixed;
        z-index: 3000;
}

.report {
	 height: 40vh;
}

.reports {
	background-color: #bbbbbb;
	width: 100%;
	padding-left: 230px;
	padding-right: 0px;
	text-align: center;
}

.container {
	margin:0px;
	padding: 0px 0px 10px;
	text-align: left;
}

.col {
	background-color: #bbbbbb;
	color: #000;
	font-size: calc(10px);
}

label {
    color: black;
}

.logo {
	align-items: center;
	width: 150px;
	margin-top:10px;
	margin-bottom:10px;
	display:block;
}

.logo img {
	align-items: center;
	width: 150px;
	margin-top:0px;
	margin-bottom:0px;
	display:block;
}

.loadingcontainer {
	width: 100%;
	height: 80px;
	align-items: center;
}

.loading {
	width: 50px;
	height: 50px;
}

.btn {
	margin-bottom:5px;
	font-size: 14px;
}

.btn-primary {
	background-color: #cccccc;
	border-color: #cccccc;
	color: #000000;
}

.btn-primary:disabled {
	background-color: #0069d9;
	border-color: #0069d9;
	color: #ffffff;
}

.datediv {
	margin-top:20px;
	margin-bottom:20px;
	display:block;
}

.superuserdiv {
	margin-top:20px;
	margin-bottom:20px;
	display:block;
}

.datediv p {
	font-size: 14px;
	margin:20px 0px 0px;
	color: #000000;
}

.periodForm {
    text-align: left;
    margin-left: 20px;
}

.appIdentifierForm {
    margin-top: 20px;
    text-align: left;
    margin-left: 20px;
}

.App-link {
	color: #61dafb;
}

.caret {
display: inline-block;
width: 0;
height: 0;
margin-left: 2px;
vertical-align: middle;
border-top: 4px dashed;
border-top: 4px solid \9;
border-right: 4px solid transparent;
border-left: 4px solid transparent;
}

.dropup, .dropdown {
position: relative;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
border-top: 0;
border-bottom: 4px dashed;
border-bottom: 4px solid \9;
content: "";
}

@media print{
	.sidebar {
		display:none !important;
	}
	.App {
		display: block !important;
	}
	div {
		display: block !important;
	}
	body {
		display: block !important;
		overflow: visible !important;
		overflow-x: visible !important; 
		overflow-y: visible !important;
	}
	.reports {
		display: block !important;
		padding-left: 0px !important;
		margin-left: 0px !important;
	}
}
.btn-sm {
	padding-top: 0px;
	padding-bottom: 0px;
	margin-bottom: 0px;
	margin-top: 0px;
}
.logoutButton {
    margin-top: 10px;
}
